@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

* {
	font-family: "Inter", sans-serif;
}

body {
	margin: 0;
	transform: translate3d(0, 0, 0);
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	background: #222;
	background-position: center bottom;
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
}

.correct-animation {
	animation: correctAnim 1s forwards;
}

@keyframes correctAnim {
	from {
		background-color: transparent;
	}
	to {
		background-color: green;
	}
}

.incorrect-animation {
	animation: incorrectAnim 1s forwards;
}

@keyframes incorrectAnim {
	from {
		background-color: transparent;
	}
	to {
		background-color: red;
	}
}

.checkmark {
	position: absolute;
	font-size: 2rem;
	color: white;
}

.cross {
	position: absolute;
	font-size: 2rem;
	color: white;
}
